@import url('https://fonts.googleapis.com/css?family=Fira+Sans:400,500,600,700,800');

* {
  box-sizing: border-box;
}
body {
  background: #f0f4f7 !important;
  min-height: 100vh;
  display: flex;
  font-weight: 400;
  font-family: 'Fira Sans', sans-serif;
}

h1,h2,h3,h4,h5,h6,label {
  font-weight: 500;
  font-family: 'Fira Sans', sans-serif;
}

body, html, .App, #root, .auth-wrapper{
  width: 100%;
  height: 100%;
}

.form-group .control-label:after {
  content:" *";
  color:red;
}

.invalid,  .invalid div{
  border-color: #de4436 !important;
}
